<script>
import AntInput from '@/components/AntInput.vue';
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import { dhmeTasksTemplate } from '@/modules/daiwa-house-modular-europe/ProjectIntake/DhmeTasksTemplate';
import { importTasksV2, queryTasksV2 } from '@/services/api/v2/tasks.v2.api';
import TasksGanttChart from '@/components/Charts/TasksGanttChart.vue';
import TasksGanttDefaultItem from '@/components/Charts/TasksGanttDefaultItem.vue';
import { updateRecordV2 } from '@/services/api/v2/records.v2.api';
import { queryTablesV2 } from '@/services/api/v2/tables.v2.api';
import { nonDhmeTasksTemplate } from '@/modules/daiwa-house-modular-europe/ProjectIntake/NonDhmeTasksTemplate';

export default {
  name: 'DhmeProjectIntakePlanning',
  components: { TasksGanttDefaultItem, TasksGanttChart, AntInput },
  data: () => {
    return {
      startDate: null,
      startDateMenu: false,
      endDate: null,
      endDateMenu: false,
      tasks: [],
      plannerLoading: false,
      planningData: null,
      planningDataTableId: null,
      prognosedMenu: false,
      contractedMenu: false,
      phaseStart: null,
    };
  },
  computed: {
    ...mapGetters({
      project: 'project',
      masterProject: 'project',
      intakeProject: 'dhmeProjectIntakeStore/selectedProject',
    }),

    prognosedDate() {
      if (this.planningData?.prognosed_handover_date) {
        return moment(this.planningData.prognosed_handover_date).format(
          'YYYY-MM-DD'
        );
      }
      return null;
    },

    contractedDate() {
      if (this.planningData?.contracted_handover_date) {
        return moment(this.planningData.contracted_handover_date).format(
          'YYYY-MM-DD'
        );
      }
      return null;
    },

    formattedPrognosedDate() {
      return moment(this.planningData?.prognosed_handover_date).format(
        'DD-MM-YYYY'
      );
    },

    formattedContractedDate() {
      return moment(this.planningData?.contracted_handover_date).format(
        'DD-MM-YYYY'
      );
    },

    formattedStartDate() {
      if (this.startDate) {
        return moment(this.startDate).format('DD-MM-YYYY');
      }
      return null;
    },

    formattedEndDate() {
      if (this.endDate) {
        return moment(this.endDate).format('DD-MM-YYYY');
      }
      return null;
    },

    duration() {
      if (this.endDate && this.startDate) {
        return moment(this.endDate).diff(moment(this.startDate), 'days');
      }
      return 0;
    },

    slack() {
      if (this.endDate && this.planningData?.contracted_handover_date) {
        return moment(this.planningData?.contracted_handover_date).diff(
          moment(this.endDate),
          'days'
        );
      }
      return 0;
    },
  },
  async mounted() {
    // fetch tasks
    const { tasks } = await queryTasksV2(this.project.license, [
      {
        column: 'project',
        operator: '=',
        values: [this.intakeProject.id],
      },
      {
        column: 'type',
        operator: '=',
        values: [
          'dhme-init-phase',
          'dhme-structure-design-phase',
          'dhme-temporary-design-phase',
          'dhme-definitive-design-phase',
          'dhme-environmental-permit-phase',
          'dhme-production-phase',
          'dhme-assembly-phase',
          'dhme-factory-phase',
          'dhme-placement-phase',
          'dhme-construction-site-phase',
          'dhme-contact-formation-phase',
          'dhme-production-drawing-phase',
          'dhme-deliver-time-phase',
          'dhme-install-assemble-finish-phase',
        ],
      },
    ]);

    this.tasks = tasks;

    await this.fetchPlanningData();
  },
  methods: {
    async planTasks() {
      this.plannerLoading = true;
      let tasks = [];
      let date = moment(this.startDate);

      let template =
        this.intakeProject.firm === 'DaiwaHouse'
          ? [...dhmeTasksTemplate]
          : [...nonDhmeTasksTemplate];
      if (this.phaseStart) {
        template = template.splice(
          dhmeTasksTemplate.findIndex(
            (t) => t.options.type === this.phaseStart
          ),
          dhmeTasksTemplate.length
        );
      }

      const totalWeight = template
        .map((x) => x.weight)
        .reduce((acc, weight) => acc + weight, 0);

      template.forEach((task) => {
        const endDate = date
          .clone()
          .add(this.duration * (task.weight / totalWeight), 'days')
          .format('YYYY-MM-DD');

        const updateTask = this.tasks.find(
          (t) => t.task_type.type === task.options.type
        );
        if (updateTask) {
          const obj = {
            id: updateTask.id,
            friendly_id: task.friendly_id,
            planned_start: date.clone().format('YYYY-MM-DD'),
            planned_end: endDate,
            due: endDate,
            parent: task.parent,
          };
          tasks.push(obj);
          date = moment(obj.planned_end);
        } else {
          const obj = {
            friendly_id: task.friendly_id,
            title: task.title,
            planned_start: date.clone().format('YYYY-MM-DD'),
            planned_end: endDate,
            due: endDate,
            project: this.planningData.project_id,
            options: task.options,
            predecessors: task.predecessors,
            parent: task.parent,
          };
          tasks.push(obj);
          if (!task.parent) {
            date = moment(obj.planned_end);
          }
        }
      });
      this.tasks = [];
      this.tasks = await importTasksV2(tasks);
      this.plannerLoading = false;
    },
    async fetchPlanningData() {
      const { planningdata } = await queryTablesV2({
        tables: [
          {
            name: 'CFFA_DHME_PROJECT_INTAKE_DATA',
            project: this.masterProject.id,
            as: 'planningdata',
            columns: [
              {
                name: 'project_id',
                conditions: [
                  {
                    operator: '=',
                    value: this.intakeProject.id,
                  },
                ],
              },
              ...['prognosed_handover_date', 'contracted_handover_date'].map(
                (key) => ({ name: key })
              ),
            ],
          },
        ],
      });

      this.planningData = planningdata.records[0];
      this.planningDataTableId = planningdata.id;
      this.isLoading = false;
    },
    async updatePlanningData(key, value) {
      this.isLoading = true;

      try {
        await updateRecordV2(this.planningDataTableId, this.planningData.id, {
          [key]: value,
        });
        this.planningData[key] = value;
      } catch (e) {
        this.$store.commit('showNotification', {
          content: e.message,
          color: 'error',
        });
      } finally {
        this.isLoading = false;
        this.prognosedMenu = false;
        this.contractedMenu = false;
      }
    },
  },
};
</script>

<template>
  <div>
    <div class="d-flex align-center">
      <ant-input
        label="Voorspelde datum (optioneel)"
        top-margin="mt-0"
        class="mr-2"
        is-optional
      >
        <template #input-field>
          <v-menu
            v-model="prognosedMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                v-bind="attrs"
                :value="formattedPrognosedDate"
                filled
                dense
                prepend-inner-icon="mdi-calendar"
                hide-details
                placeholder="Datum"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              :value="prognosedDate"
              show-week
              @input="
                (value) => updatePlanningData('prognosed_handover_date', value)
              "
            />
          </v-menu>
        </template>
      </ant-input>
      <ant-input label="Gecontracteerde datum" top-margin="mt-0" class="mr-2">
        <template #input-field>
          <v-menu
            v-model="contractedMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                :value="formattedContractedDate"
                filled
                v-bind="attrs"
                dense
                prepend-inner-icon="mdi-calendar"
                hide-details
                placeholder="Datum"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              :value="contractedDate"
              show-week
              @input="
                (value) => updatePlanningData('contracted_handover_date', value)
              "
            />
          </v-menu>
        </template>
      </ant-input>
      <ant-input label="Vanaf fase" top-margin="mt-0">
        <template #input-field>
          <v-select
            v-model="phaseStart"
            filled
            item-text="text"
            item-value="value"
            dense
            hide-details
            placeholder="Phase"
            :items="[
              { text: 'Initialisatie', value: 'dhme-init-phase' },
              { text: 'SO', value: 'dhme-structure-design-phase' },
              { text: 'TO', value: 'dhme-temporary-design-phase' },
              { text: 'DO', value: 'dhme-definitive-design-phase' },
            ]"
          ></v-select>
        </template>
      </ant-input>
    </div>
    <div class="d-flex mt-5">
      <ant-input label="Start datum" top-margin="mt-0" class="mr-2">
        <template #input-field>
          <v-menu
            v-model="startDateMenu"
            :disabled="!contractedDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                :value="formattedStartDate"
                prepend-inner-icon="mdi-calendar-start"
                readonly
                filled
                dense
                :disabled="!contractedDate || plannerLoading"
                hide-details
                placeholder="Datum"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="startDate"
              show-week
              @input="startDateMenu = false"
            ></v-date-picker>
          </v-menu>
        </template>
      </ant-input>
      <ant-input label="Eind datum" top-margin="mt-0" class="mr-2">
        <template #input-field>
          <v-menu
            v-model="endDateMenu"
            :disabled="!contractedDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                :value="formattedEndDate"
                prepend-inner-icon="mdi-calendar-end"
                readonly
                filled
                :disabled="!contractedDate || plannerLoading"
                dense
                hide-details
                placeholder="Datum"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="endDate"
              :disabled="!startDate"
              show-week
              :min="startDate"
              :max="contractedDate"
              @input="endDateMenu = false"
            ></v-date-picker>
          </v-menu>
        </template>
      </ant-input>
      <ant-input label="Duur" top-margin="mt-0" class="mr-2" is-optional>
        <template #input-field>
          <v-text-field
            :value="duration"
            :disabled="!contractedDate || plannerLoading"
            prepend-inner-icon="mdi-calendar-clock"
            readonly
            filled
            dense
            hide-details
            placeholder="Day(s)"
          ></v-text-field>
        </template>
      </ant-input>
      <ant-input label="Speling" top-margin="mt-0" class="mr-2" is-optional>
        <template #input-field>
          <v-text-field
            :value="slack"
            :disabled="!contractedDate || plannerLoading"
            prepend-inner-icon="mdi-calendar-arrow-right"
            readonly
            filled
            dense
            hide-details
            suffix="Dag(en)"
          ></v-text-field>
        </template>
      </ant-input>
      <v-spacer />
      <v-btn
        color="primary"
        class="mt-5 flex-1"
        :loading="plannerLoading"
        :disabled="(!(duration > 0) && slack >= 0) || plannerLoading"
        @click="planTasks"
        >Plan</v-btn
      >
    </div>
    <div
      class="ma-5 d-flex flex-column flex-1 flex-scroll-width overflow-x-auto"
      style="max-width: calc(100vw - 350px)"
    >
      <tasks-gantt-chart
        v-if="tasks.length > 0"
        :tasks="tasks.filter((t) => t.parent === null)"
      >
        <template #task-header-items>
          <tasks-gantt-default-item
            v-for="(task, index) in tasks.filter((t) => t.parent === null)"
            :key="`ant-gantt-header-${task.id}`"
            :task="task"
            :index="index"
            :parents="[]"
            :can-load-children="false"
          />
        </template>
      </tasks-gantt-chart>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
