export const dhmeTasksTemplate = [
  {
    friendly_id: '1',
    title: 'Init Phase',
    options: {
      type: 'dhme-init-phase',
    },
    weight: 0.03,
  },
  {
    friendly_id: '2',
    title: 'Structure Design Phase',
    options: {
      type: 'dhme-structure-design-phase',
    },
    weight: 0.04,
    predecessors: ['1'],
  },
  {
    friendly_id: '3',
    title: 'Temporary Design Phase',
    options: {
      type: 'dhme-temporary-design-phase',
    },
    weight: 0.06,
    predecessors: ['2'],
  },
  {
    friendly_id: '4',
    title: 'Definitive Design Phase',
    options: {
      type: 'dhme-definitive-design-phase',
    },
    weight: 0.05,
    predecessors: ['3'],
  },
  {
    friendly_id: '5',
    title: 'Environmental Permit',
    options: {
      type: 'dhme-environmental-permit-phase',
    },
    weight: 0.08,
    predecessors: ['4'],
  },
  {
    friendly_id: '6',
    title: 'Factory',
    options: {
      type: 'dhme-factory-phase',
    },
    weight: 0.2,
    predecessors: ['5'],
  },
  {
    friendly_id: '600',
    title: 'Factory Production',
    options: {
      type: 'dhme-production-phase',
    },
    weight: 0.2,
    parent: '6',
  },
  {
    friendly_id: '601',
    title: 'Factory Assembly',
    options: {
      type: 'dhme-assembly-phase',
    },
    weight: 0.2,
    parent: '6',
  },
  {
    friendly_id: '7',
    title: 'Construction Site',
    options: {
      type: 'dhme-construction-site-phase',
    },
    weight: 0.24,
    predecessors: ['6'],
  },
  {
    friendly_id: '700',
    title: 'Placement phase',
    options: {
      type: 'dhme-placement-phase',
    },
    weight: 0.24,
    parent: '7',
  },
  {
    friendly_id: '8',
    title: 'Contact Formation',
    options: {
      type: 'dhme-contact-formation-phase',
    },
    weight: 0.08,
    predecessors: ['7'],
  },
  {
    friendly_id: '9',
    title: 'Production Drawing',
    options: {
      type: 'dhme-production-drawing-phase',
    },
    weight: 0.13,
    predecessors: ['8'],
  },
  {
    friendly_id: '10',
    title: 'Deliver time',
    options: {
      type: 'dhme-deliver-time-phase',
    },
    weight: 0.04,
    predecessors: ['9'],
  },
  {
    friendly_id: '11',
    title: 'Installation, Assembly & Finishing phase',
    options: {
      type: 'dhme-install-assemble-finish-phase',
    },
    weight: 0.05,
    predecessors: ['10'],
  },
];
