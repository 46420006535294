export const nonDhmeTasksTemplate = [
    {
      friendly_id: "1",
      title: "Commercie",
      options: {
        type: "dhme-init-phase"
      },
      weight: 2,
    },
    {
      friendly_id: "2",
      title: "Ontwerpfase",
      options: {
        type: "dhme-definitive-design-phase"
      },
      weight: 14,
      parent: null,
      predecessors: ["1"]
    },
    {
      friendly_id: "3",
      title: "Uitwerken definitief ontwerp",
      options: {
        type: "dhme-definitive-design-phase"
      },
      weight: 28,
      parent: null,
      predecessors: ["2"]
    },
    {
      friendly_id: "4",
      title: "Omgevingsvergunning bouwen (Goedkeuring & inzage)",
      options: {
        type: "dhme-environmental-permit-phase"
      },
      weight: 98,
      parent: null,
      predecessors: ["3"]
    },
    {
      friendly_id: "5",
      title: "Uitwerken technisch ontwerp (engineering)",
      options: {
        type: "dhme-production-drawing-phase"
      },
      weight: 56,
      parent: null,
      predecessors: ["4"]
    },
    {
      friendly_id: "6",
      title: "Productie",
      options: {
        type: "dhme-factory-phase"
      },
      weight: 14,
      parent: null,
      predecessors: ["5"]
    },
    {
      friendly_id: '600',
      title: 'Factory Production',
      options: {
        type: 'dhme-production-phase',
      },
      weight: 14,
      parent: '6',
    },
    {
      friendly_id: '601',
      title: 'Factory Assembly',
      options: {
        type: 'dhme-assembly-phase',
      },
      weight: 14,
      parent: '6',
    },
    {
      friendly_id: "7",
      title: "Realisatie op locatie",
      options: {
        type: "dhme-construction-site-phase"
      },
      weight: 2,
      parent: null,
      predecessors: ["6"]
    },
    {
      friendly_id: "8",
      title: "Grondwerk & fundering / bouwrijp maken",
      options: {
        type: "dhme-construction-site-phase"
      },
      weight: 7,
      parent: null,
      predecessors: ["7"]
    },
    {
      friendly_id: "9",
      title: "Plaatsing modules",
      options: {
        type: "dhme-placement-phase"
      },
      weight: 2,
      parent: null,
      predecessors: ["8"]
    },
    {
      friendly_id: "10",
      title: "Afmonteren & woonrijp",
      options: {
        type: "dhme-install-assemble-finish-phase"
      },
      weight: 42,
      parent: null,
      predecessors: ["9"]
    },
  ];